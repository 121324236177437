'use strict'

const setPageHeight = (page) => {
  page.removeAttribute('style')
  const initialPageHeight = parseInt(page.getBoundingClientRect().height)
  const winHeight = window.innerHeight - 20
  const header = document.querySelector('.main-header')
  const headerHeight = header.getBoundingClientRect().height
  const footer = document.querySelector('.main-footer')
  const footerHeight = footer.getBoundingClientRect().height
  const footerStyle = window.getComputedStyle(footer)
  const footerMargin = parseFloat(footerStyle.marginTop)
  const height = parseInt(winHeight - headerHeight - footerHeight - footerMargin)
  page.setAttribute('style', `height: ${height}px; min-height: ${initialPageHeight}px;`)
}

const forcePageHeight = (el) => {
  const page = document.querySelector(el)
  if (page) {
    setPageHeight(page)
    window.addEventListener('resize', () => {
      setPageHeight(page)
    })
  }
}

export default class Sizing {
  pageHeights () {
    forcePageHeight('#contact-page')
    forcePageHeight('#newsletter-page')
  }

  init () {
    this.pageHeights()
  }
}
