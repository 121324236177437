'use strict'

import throttle from '../../utils/throttle'

export default class SetHeaderTheme {
  constructor (config) {
    this.config = config
    this.hero = document.querySelector(this.config.hero)
    this.mainHeader = document.querySelector(this.config.mainHeader)
  }

  setHeaderTheme () {
    const scrollCb = () => {
      this.scrollDistanceHandler()
    }
    const throttledCb = throttle(scrollCb, 60)
    scrollCb()
    window.addEventListener('scroll', throttledCb)
  }

  scrollDistanceHandler () {
    const heroRect = this.hero.getBoundingClientRect()
    const height = heroRect.height
    const offset = heroRect.top
    const limit = Math.abs(offset) + 50
    if (limit >= height) {
      this.mainHeader.classList.remove('is-white')
    } else {
      this.mainHeader.classList.add('is-white')
    }
  }

  init () {
    this.setHeaderTheme()
  }
}
