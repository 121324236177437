'use strict'

import Visibility from './lib/visibility'

export default class AllTags {
  constructor () {
    this.config = {
      toggle: '.all-tags__toggle',
      icon: '.all-tags__toggle-icon',
      container: '.all-tags__list-container',
      list: '.all-tags__list'
    }
  }

  init () {
    new Visibility(this.config).init()
  }
}
