'use strict'

import Velocity from 'velocity-animate'

export default class ClaimHandler {
  constructor (config) {
    this.config = config
    this.items = document.querySelector(this.config.items)
    this.brand = document.querySelector(this.config.mainHeaderBrand)
    this.mainHeader = document.querySelector(this.config.mainHeader)
  }

  hoverEvents () {
    let toggleHeaderClass
    const hideCb = () => {
      this.hideItems()
      this.mainHeader.classList.add('hide-navs')
      if (toggleHeaderClass) {
        this.mainHeader.classList.remove('is-white')
      }
    }
    const showCb = () => {
      this.showItems()
      this.mainHeader.classList.remove('hide-navs')
      if (toggleHeaderClass) {
        this.mainHeader.classList.add('is-white')
      }
    }
    if (this.mainHeader.classList.contains('is-white')) {
      toggleHeaderClass = true
    }
    this.brand.addEventListener('mouseenter', hideCb)
    this.brand.addEventListener('mouseleave', showCb)
  }

  hideItems () {
    Velocity(this.items, 'stop')
    Velocity(this.items, {opacity: 0})
  }

  showItems () {
    Velocity(this.items, 'stop')
    Velocity(this.items, {opacity: 1})
  }

  init () {
    this.hoverEvents()
  }
}
