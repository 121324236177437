'use strict'

import matchmediaHandler from '../utils/matchmedia-handler'
import MousemoveAnimation from './lib/mousemove-animation'
import HeroClickHandler from './lib/hero-click-handler'
import ClaimHandler from './lib/claim-handler'

export default class HomeHero {
  constructor () {
    this.config = {
      hero: '.home-hero',
      items: '.home-hero__items-container',
      item: '.home-hero__item',
      claim: '.home-hero__claim',
      mainContent: '#mainContent',
      mainHeader: '.main-header',
      mainHeaderBrand: '.main-header__brand'
    }
    this.hero = document.querySelector(this.config.hero)
  }

  init () {
    const matchCb = () => {
      this.hero.classList.add('is-initialized')
      new MousemoveAnimation(this.config).init()
      new HeroClickHandler(this.config).init()
      new ClaimHandler(this.config).init()
    }
    if (this.hero) {
      matchmediaHandler('MEDIUM', matchCb)
    }
  }
}
