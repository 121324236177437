'use strict'

import Velocity from 'velocity-animate'
import SetHeaderTheme from './set-header-theme'
import throttle from '../../utils/throttle'

export default class MousemoveAnimation {
  constructor (config) {
    this.config = config
    this.winW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    this.winH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    this.items = document.querySelector(this.config.items)
    this.hero = document.querySelector(this.config.hero)
    this.mainHeader = document.querySelector(this.config.mainHeader)
  }

  localStorageHandler (itemLength) {
    let currIndex
    const storageData = localStorage.getItem('homeHeroCurrItem_a44f1cb7')
    if (storageData) {
      if (parseInt(storageData) === (itemLength - 1)) {
        currIndex = 0
      } else {
        currIndex = parseInt(storageData) + 1
      }
    } else {
      currIndex = 0
    }
    localStorage.setItem('homeHeroCurrItem_a44f1cb7', currIndex)
    this.parseItems(currIndex)
  }

  parseItems (currIndex) {
    const items = document.querySelectorAll(this.config.item)
    Array.prototype.forEach.call(items, (item, i) => {
      if (i === currIndex) {
        item.classList.add('is-visible')
        item.classList.add('image-is-visible')
        this.mouseMoveHandler(item)

        if (!item.classList.contains('no-ui-theme')) {
          new SetHeaderTheme(this.config).init()
        }
      }
    })
  }

  mouseMoveHandler (item) {
    const image = item.querySelector('.home-hero__image')
    const handler = throttle((e) => {
      const x = e.pageX
      const y = e.pageY
      this.moveImage(image, x, y)
    }, 100)
    item.addEventListener('mousemove', handler)
  }

  moveImage (image, x, y) {
    const xPct = parseInt((x / this.winW) * 100)
    const yPct = parseInt((y / this.winH) * 100)
    const distance = (axisPct) => {
      return 3.5 - (((axisPct * 2) / 100) * 3.5) + '%'
    }

    Velocity(image, 'stop')
    Velocity(image, {
      translateX: distance(xPct),
      translateY: distance(yPct)
    }, {
      duration: 1800,
      easing: 'easeOutQuart'
    })
  }

  winResizeHandler () {
    const cb = throttle(() => {
      // Update viewport dimensions
      this.winW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      this.winH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    }, 1000)
    window.addEventListener('resize', cb)
  }

  init () {
    const items = document.querySelectorAll(this.config.item)
    const itemsLength = items.length
    this.localStorageHandler(itemsLength)
    this.winResizeHandler()
  }
}
