'use strict'

import Constants from '../constants/constants'

const matchmediaHandler = (mqName, matchFun, unmatchFun) => {
  const mq = new Constants().MEDIA_QUERIES[mqName]
  const mql = window.matchMedia(mq)
  const mediaQueryHandler = mql => {
    if (mql.matches) {
      if (typeof matchFun === 'function') {
        matchFun()
      }
    } else {
      if (typeof unmatchFun === 'function') {
        unmatchFun()
      }
    }
  }
  mql.addListener(mediaQueryHandler)
  mediaQueryHandler(mql)
}

export default matchmediaHandler
