'use strict'

import NavVisibility from './lib/nav-visibility'
import Sticky from './lib/sticky'

export default class MainHeader {
  constructor () {
    this.config = {
      mainHeader: '.main-header',
      showNav: '.main-header__show-nav',
      hideNav: '.main-header__hide-nav',
      nav: '.main-header__nav',
      overlay: '.main-header__overlay'
    }
    this.mainHeader = document.querySelector(this.config.mainHeader)
  }

  init () {
    new NavVisibility(this.config).init()
    new Sticky(this.config).init()
  }
}
