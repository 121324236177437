'use strict'

import Velocity from 'velocity-animate'

export default class Visibility {
  constructor (config) {
    this.config = config
    this.toggle = document.querySelector(this.config.toggle)
    this.icon = document.querySelector(this.config.icon)
    this.container = document.querySelector(this.config.container)
    this.list = document.querySelector(this.config.list)
  }

  clickHandler () {
    const cb = () => {
      this.checkVisibility()
    }
    this.toggle.addEventListener('click', cb)
  }

  checkVisibility () {
    const visible = this.container.classList.contains('is-visible')
    const animating = this.container.classList.contains('velocity-animating')
    if (visible && !animating) {
      this.hideContainer()
    } else if (!visible && !animating) {
      this.showContainer()
    }
  }

  hideContainer () {
    this.container.classList.remove('is-visible')
    Velocity(this.icon, {
      rotateZ: ['-45deg', '0deg']
    }, {
      duration: 300
    })
    Velocity(this.list, {
      opacity: 0
    }, {
      duration: 300,
      complete: () => {
        Velocity(this.container,
          'slideUp', {
            easing: 'easeOutQuart'
          })
      }
    })

    if (window.ga) {
      window.ga('send', 'event', {
        eventCategory: 'Interaccions',
        eventAction: 'Amagar el menú de les etiquetes'
      })
    }
  }

  showContainer () {
    this.container.classList.add('is-visible')
    Velocity(this.icon, {
      rotateZ: ['0deg', '-45deg']
    }, {
      duration: 300
    })
    Velocity(this.container,
      'slideDown', {
        easing: 'easeOutQuart',
        complete: () => {
          Velocity(this.list, {
            opacity: 1
          }, {
            duration: 500
          })
        }
      })

    if (window.ga) {
      window.ga('send', 'event', {
        eventCategory: 'Interaccions',
        eventAction: 'Mostrar el menú de les etiquetes'
      })
    }
  }

  init () {
    if (this.toggle) {
      this.clickHandler()
    }
  }
}
