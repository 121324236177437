'use strict'

import Velocity from 'velocity-animate'

export default class ScrollTo {
  constructor () {
    this.config = {
      button: '[data-scroll-to]'
    }
  }

  parseButtons () {
    const buttons = document.querySelectorAll(this.config.button)
    Array.prototype.forEach.call(buttons, (button) => {
      const targetId = button.getAttribute('data-scroll-to')
      const target = document.querySelector(targetId)
      this.clickHandler(button, target)
    })
  }

  clickHandler (button, target) {
    const cb = () => {
      this.scrollAnimation(target)
    }
    button.addEventListener('click', cb)
  }

  scrollAnimation (target) {
    Velocity(target, 'stop')
    Velocity(target,
      'scroll', {
        easing: 'easeOutQuart',
        duration: 2300
      })
  }

  init () {
    this.parseButtons()
  }
}
