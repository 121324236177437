'use strict'

class GaEvents {
  sendEvent (eventAction) {
    if (window.ga) {
      window.ga('send', 'event', {
        eventCategory: 'Interaccions',
        eventAction
      })
    }
  }

  clickEvent (el, action) {
    const cb = () => {
      this.sendEvent(action)
    }
    el.addEventListener('click', cb)
  }

  init () {
    const mailLink = document.querySelector('#contactMailLink')
    if (mailLink) {
      this.clickEvent(mailLink, 'Fer click a l’enllaç del mail')
    }

    const phoneLink = document.querySelector('#contactPhoneLink')
    if (phoneLink) {
      this.clickEvent(phoneLink, 'Fer click a l’enllaç del telèfon')
    }

    const tumblrLink = document.querySelector('#mainHeaderSocialLinkTumblr')
    if (tumblrLink) {
      this.clickEvent(tumblrLink, 'Fer click a l’enllaç del Tumblr')
    }

    const twitterLink = document.querySelector('#mainHeaderSocialLinkTwitter')
    if (twitterLink) {
      this.clickEvent(twitterLink, 'Fer click a l’enllaç de Twitter')
    }

    const facebookLink = document.querySelector('#mainHeaderSocialLinkFacebook')
    if (facebookLink) {
      this.clickEvent(facebookLink, 'Fer click a l’enllaç de Facebook')
    }

    const instagramLink = document.querySelector('#mainHeaderSocialLinkInstagram')
    if (instagramLink) {
      this.clickEvent(instagramLink, 'Fer click a l’enllaç de Instagram')
    }

    const editorialLink = document.querySelector('#mainHeaderLinkEditorial')
    if (editorialLink) {
      this.clickEvent(editorialLink, 'Fer click a l’enllaç Editorial')
    }

    const editorialLinkAlpha = document.querySelector('#mainHeaderLinkEditorialAlpha')
    if (editorialLinkAlpha) {
      this.clickEvent(editorialLinkAlpha, 'Fer click a l’enllaç Editorial')
    }
  }
}

export default GaEvents
