'use strict'

import 'lazysizes'
// import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import matchmediaHandler from '../utils/matchmedia-handler'

export default class Image {
  constructor() {
    this.config = {
      checkLazyload: '.check-lazyload'
    }
  }

  parseImages() {
    const images = document.querySelectorAll(this.config.checkLazyload)
    Array.prototype.forEach.call(images, image => {
      if (image.hasAttribute('data-min-breakpoint')) {
        const minBp = image.getAttribute('data-min-breakpoint')
        const minBpName = minBp.toUpperCase()
        const matchFun = () => {
          this.addLazyloadClass(image)
        }
        matchmediaHandler(minBpName, matchFun)
      } else {
        this.addLazyloadClass(image)
      }
    })
  }

  addLazyloadClass(image) {
    image.classList.add('lazyload')
    image.addEventListener('load', () => {
      image.parentNode.classList.add('is-loaded')
    })
  }

  init() {
    window.lazySizesConfig.preloadAfterLoad = true
    this.parseImages()
  }
}
