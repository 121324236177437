const isInViewport = function(elem) {
  const distance = elem.getBoundingClientRect()
  return (
    distance.top >= -(distance.height / 2) &&
    distance.left >= 0 &&
    distance.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) +
        distance.height / 2 &&
    distance.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

export default class VideoInviewPlay {
  constructor() {
    this.init()
  }

  parseElements() {
    const els = document.querySelectorAll('.video-inview-play')

    if (!els) return

    Array.prototype.forEach.call(els, el => {
      const video = el.querySelector('video')
      window.addEventListener('scroll', () => this.handleInViewport(video))
    })
  }

  handleInViewport(video) {
    if (video.readyState !== 4) return

    const inViewport = isInViewport(video)
    if (inViewport) {
      video.play()
    } else {
      video.pause()
      video.currentTime = 0
    }
  }

  init() {
    this.parseElements()
  }
}
