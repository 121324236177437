'use strict'

import Submit from './lib/submit'

export default class NewsletterForm {
  constructor () {
    this.config = {
      form: '#mc-embedded-subscribe-form',
      input: '#mce-EMAIL',
      submit: '#mc-embedded-subscribe',
      messageEl: '.newsletter-form__message',
      messageText: '.newsletter-form__message-text',
      validEmailRegex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      messages: () => {
        const el = document.querySelector('.newsletter-form__messages')
        const messages = JSON.parse(el.textContent)
        return messages
      }
    }
    this.form = document.querySelector(this.config.form)
  }

  init () {
    if (this.form) {
      new Submit(this.config).init()
    }
  }
}
