'use strict'

import Velocity from 'velocity-animate'
import serializeForm from '../../utils/serialize-form'

export default class Submit {
  constructor (config) {
    this.config = config
    this.form = document.querySelector(this.config.form)
    this.input = document.querySelector(this.config.input)
    this.submit = document.querySelector(this.config.submit)
    this.messageEl = document.querySelector(this.config.messageEl)
    this.messageText = document.querySelector(this.config.messageText)
    this.validEmailRegex = this.config.validEmailRegex
    this.messages = this.config.messages()
  }

  clickHandler () {
    const cb = (event) => {
      if (this.validateForm(event)) {
        this.register()
      }
    }
    this.submit.addEventListener('click', cb)
  }

  validateForm () {
    const isIncomplete = () => {
      return this.input.value === ''
    }

    const isValidEmail = () => {
      return this.validEmailRegex.test(this.input.value)
    }

    if (isIncomplete()) {
      event.preventDefault()
      const message = this.messages.incompleteAlert
      this.changeMessage(message)
      return false
    } else if (!isValidEmail()) {
      event.preventDefault()
      const message = this.messages.invalidAlert
      this.changeMessage(message)
      return false
    } else {
      return true
    }
  }

  register () {
    const action = this.form.getAttribute('action')
    const data = serializeForm(this.form)
    const submitted = (event) => {
      event.preventDefault()
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = action + '&c=document.MC_callback&' + data
      document.MC_callback = (response) => {
        if (response.result === 'sucess') {
          this.hideForm()
          const message = response.msg
          this.changeMessage(message)
        } else {
          const message = response.msg
          this.changeMessage(message)
        }
      }
      document.querySelector('head').appendChild(script)
    }
    this.form.addEventListener('submit', submitted)
  }

  changeMessage (newMessage) {
    const oldMessage = this.messageText.innerHTML
    const isNewMessage = oldMessage !== newMessage
    const visible = this.messageEl.classList.contains('is-visible')
    const setNewMessage = () => {
      this.messageText.innerHTML = newMessage
      this.showMessage()
    }
    if (visible && isNewMessage) {
      this.hideMessage(setNewMessage)
    } else if (isNewMessage) {
      setNewMessage()
    }
  }

  showMessage () {
    const visible = this.messageEl.classList.contains('is-visible')
    const animating = this.messageEl.classList.contains('velocity-animating')
    if (!visible && !animating) {
      this.messageEl.classList.add('is-visible')
      Velocity(this.messageEl, 'slideDown')
      Velocity(this.messageEl, {
        opacity: 1
      })
    }
  }

  hideMessage (cb) {
    const visible = this.messageEl.classList.contains('is-visible')
    const animating = this.messageEl.classList.contains('velocity-animating')
    if (visible && !animating) {
      this.messageEl.classList.remove('is-visible')
      Velocity(this.messageEl, {
        opacity: 0
      }, {
        complete: () => {
          Velocity(this.messageEl,
            'slideUp', {
              complete: () => {
                if (typeof cb === 'function') cb()
              }
            })
        }
      })
    }
  }

  hideForm () {
    Velocity(this.form, {
      opacity: 0
    })
    Velocity(this.form, 'slideUp')
  }

  init () {
    this.clickHandler()
  }
}
