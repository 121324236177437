'use strict'

import Velocity from 'velocity-animate'

export default class NavVisibility {
  constructor (config) {
    this.config = config
    this.showButton = document.querySelector(this.config.showNav)
    this.hideButton = document.querySelector(this.config.hideNav)
    this.nav = document.querySelector(this.config.nav)
    this.overlay = document.querySelector(this.config.overlay)
  }

  clickHandler (el, cb) {
    el.addEventListener('click', cb)
  }

  showNav () {
    const visible = this.nav.classList.contains('is-visible')
    const animating = this.nav.classList.contains('velocity-animating')
    if (!visible && !animating) {
      this.nav.classList.add('is-visible')
      this.showOverlay()
      Velocity(this.nav, {
        translateX: ['0%', '105%']
      }, {
        easing: 'easeOutQuart'
      })

      if (window.ga) {
        window.ga('send', 'event', {
          eventCategory: 'Interaccions',
          eventAction: 'Mostrar el menú principal'
        })
      }
    }
  }

  hideNav () {
    const visible = this.nav.classList.contains('is-visible')
    const animating = this.nav.classList.contains('velocity-animating')
    if (visible && !animating) {
      this.nav.classList.remove('is-visible')
      this.hideOverlay()
      Velocity(this.nav, {
        translateX: ['105%', '0%']
      }, {
        easing: 'easeOutQuart'
      })

      if (window.ga) {
        window.ga('send', 'event', {
          eventCategory: 'Interaccions',
          eventAction: 'Amagar el menú principal'
        })
      }
    }
  }

  showOverlay () {
    this.overlay.classList.add('is-visible')
  }

  hideOverlay () {
    this.overlay.classList.remove('is-visible')
  }

  init () {
    const showCb = () => {
      this.showNav()
    }
    const hideCb = () => {
      this.hideNav()
    }
    this.clickHandler(this.showButton, showCb)
    this.clickHandler(this.hideButton, hideCb)
    this.clickHandler(this.overlay, hideCb)
  }
}
