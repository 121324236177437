// Main javascript entry point
// Should handle bootstrapping/starting application
'use strict'

import '../_modules/modernizr/modernizr'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'
import Image from '../_modules/image/image'
import MainHeader from '../_modules/main-header/main-header'
import LangNav from '../_modules/lang-nav/lang-nav'
import HomeHero from '../_modules/home-hero/home-hero'
import AllTags from '../_modules/all-tags/all-tags'
import NewsletterForm from '../_modules/newsletter-form/newsletter-form'
import ScrollTo from '../_modules/scroll-to/scroll-to'
import GaEvents from '../_modules/ga-events/ga-events'
import Sizing from '../_modules/sizing/sizing'
import VideoInviewPlay from '../_modules/video-inview-play/video-inview-play'

viewportUnitsBuggyfill.init()
new Image().init()

const initFun = () => {
  new MainHeader().init()
  new LangNav().init()
  new HomeHero().init()
  new AllTags().init()
  new NewsletterForm().init()
  new ScrollTo().init()
  new GaEvents().init()
  new Sizing().init()
  new VideoInviewPlay()
}

function documentReady(fn) {
  if (document.readyState !== 'loading') {
    fn()
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', fn)
  } else {
    document.attachEvent('onreadystatechange', function() {
      if (document.readyState !== 'loading') {
        fn()
      }
    })
  }
}

documentReady(initFun)
