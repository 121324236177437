'use strict'

import Velocity from 'velocity-animate'

export default class Visibility {
  constructor (config) {
    this.config = config
    this.navs = document.querySelectorAll(this.config.nav)
  }

  parseNavs () {
    Array.prototype.forEach.call(this.navs, (nav) => {
      const list = nav.querySelector(this.config.list)
      if (!nav.classList.contains('-show-full')) {
        const toggle = nav.querySelector(this.config.toggle)
        const arrow = nav.querySelector(this.config.arrow)
        this.clickOutsideHandler(nav, list, arrow)
        this.clickHandler(list, arrow, toggle)
      }
    })
  }

  clickOutsideHandler (nav, list, arrow) {
    const cb = (event) => {
      const isClickInside = nav.contains(event.target)
      const isVisible = list.classList.contains('is-visible')
      if (!isClickInside && isVisible) {
        this.hideList(list, arrow)
      }
    }
    document.addEventListener('click', cb)
  }

  clickHandler (list, arrow, toggle) {
    const cb = () => {
      this.checkVisibility(list, arrow)
    }
    toggle.addEventListener('click', cb)
  }

  checkVisibility (list, arrow) {
    const visible = list.classList.contains('is-visible')
    const animating = list.classList.contains('velocity-animating')
    if (visible && !animating) {
      this.hideList(list, arrow)
    } else if (!visible && !animating) {
      this.showList(list, arrow)
    }
  }

  hideList (list, arrow) {
    list.classList.remove('is-visible')
    document.documentElement.classList.remove('lang-nav-is-open')
    Velocity(list,
      'fadeOut', {
        duration: 150
      })
    Velocity(arrow, {
      rotateZ: ['90deg', '-90deg']
    }, {
      duration: 200
    })
  }

  showList (list, arrow) {
    list.classList.add('is-visible')
    document.documentElement.classList.add('lang-nav-is-open')
    Velocity(list, 'fadeIn')
    Velocity(arrow, {
      rotateZ: ['-90deg', '90deg']
    }, {
      duration: 200
    })

    if (window.ga) {
      window.ga('send', 'event', {
        eventCategory: 'Interaccions',
        eventAction: 'Fer click al menú idiomes'
      })
    }
  }

  init () {
    this.parseNavs()
  }
}
