'use strict'

import Headroom from 'headroom.js'

export default class Sticky {
  constructor (config) {
    this.config = config
    this.mainHeader = document.querySelector(this.config.mainHeader)
  }

  initHeadroom () {
    const headroom = new Headroom(this.mainHeader, {
      offset: 300,
      tolerance: 100
    })
    headroom.init()
  }

  init () {
    this.initHeadroom()
  }
}
