'use strict'

import Visibility from './lib/visibility'

export default class LangNav {
  constructor () {
    this.config = {
      nav: '.lang-nav',
      list: '.lang-nav__list',
      toggle: '.lang-nav__toggle',
      arrow: '.lang-nav__arrow'
    }
  }

  init () {
    new Visibility(this.config).init()
  }
}
