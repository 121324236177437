'use strict'

import Velocity from 'velocity-animate'

export default class HeroClickHandler {
  constructor (config) {
    this.config = config
    this.hero = document.querySelector(this.config.hero)
    this.mainContent = document.querySelector(this.config.mainContent)
  }

  clickHandler () {
    const cb = () => {
      Velocity(this.mainContent, 'scroll', {
        duration: 1200,
        easing: 'easeOutQuart',
        mobileHA: false
      })
    }
    this.hero.addEventListener('click', cb)
  }

  init () {
    this.clickHandler()
  }
}
